import { IColumnConfig, IDualSelectItem, ITableConfig } from "~/common/types";
import * as crypto from 'crypto';
import * as pkg from "vue-toastification"
const { useToast } = pkg

// Extend PluginOptions to include 'type' as a property
interface ToastOptions {
  type?: 'success' | 'error';
}

export function showToastMessage(message: string, options: ToastOptions = {}) {
  const toast = useToast();
  toast(message, options as any);
}

export function getSHA256Hash(text: string): string {
  const hash = crypto.createHash('sha256');
  hash.update(text);
  return hash.digest('hex');
}

export function findWithAttr<T>(array: T[], attr: keyof T, value: T[keyof T]): T | null {
  if (!array?.length) return null;
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return array[i];
    }
  }
  return null;
}

export function getTableFor(tables: ITableConfig[], tableName: string): ITableConfig | null {
  return findWithAttr(tables, "name", tableName)
}

export function getColumnsFor(tables: ITableConfig[], tableName: string): IColumnConfig[] {
  return getTableFor(tables, tableName)?.columns ?? []
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function safeJsonParse(str: string) {
  let parsed;

  try {
    parsed = JSON.parse(str);
  } catch (error) {
    console.error(`JSON Parse Error: ${str}`);
    console.error(error);
  }

  return parsed;
}

export function deepCopy<T>(obj: T): T {
  return safeJsonParse(JSON.stringify(obj));
}

export function getCurrentUserId(): string | undefined {
  const user = useSupabaseUser();
  return user.value?.id;
}

export function sortByIds(idList: string[], objectList: IDualSelectItem[]): IDualSelectItem[] {
  // Create a map to store the index of each id in the idList
  const idIndexMap = new Map<string, number>();
  idList.forEach((id, index) => {
    idIndexMap.set(id, index);
  });

  // Use the sort method to sort the objectList based on the id order
  objectList.sort((a, b) => {
    const indexA = idIndexMap.get(a.id) || 0;
    const indexB = idIndexMap.get(b.id) || 0;

    // Compare the indexes to determine the order
    return indexA - indexB;
  });

  return objectList;
}

export interface IApiResponse {
  statusCode: number
  errorMessage?: string
  userId?: string
  examinee?: any
}

interface Cookies {
  [key: string]: string;
}

function customParseCookies(cookieString: string): Cookies {
  const cookies: Cookies = {};
  cookieString.split(';').forEach(cookie => {
    const [name, value] = cookie.trim().split('=');
    cookies[name] = value;
  });
  return cookies;
}

export async function handleApiRequest(event: any, client: any): Promise<IApiResponse> {
  const response: IApiResponse = {
    statusCode: 200,
  }
  try {
    // Check Authorization header
    if (!event.req.headers['authorization'] || !event.req.headers['authorization'].startsWith('Bearer ')) {
      return {
        statusCode: 401,
        errorMessage: 'Invalid or missing Authorization header'
      }
    }

    // Extract Supabase session token from headers
    const supabaseSessionToken = event.req.headers['authorization'].replace("Bearer ", "");

    // Get the user's session
    const userResponse = await client.auth.getUser(supabaseSessionToken);

    if (userResponse.error) {
      if (userResponse.error.message.includes('invalid JWT')) {
        const cookieString: string = event.req.headers['cookie'];
        const cookies = customParseCookies(cookieString);
        const decodedCookieValue = decodeURIComponent(cookies?.session);
        const session = safeJsonParse(decodedCookieValue)
        if (session) {
          const authResponse = await client.auth.refreshSession({ refresh_token: session['refresh_token'] });
          if (authResponse.error) {
            return {
              statusCode: authResponse.error?.status ?? 401,
              errorMessage: authResponse.error?.message
            };
          } else {
            setCookie(event, 'session', JSON.stringify(authResponse.data.session));
            response.examinee = authResponse.data.user;
          }
        } else {
          return {
            statusCode: 401,
            errorMessage: 'Cookie session not found'
          };
        }
      } else {
        return {
          statusCode: userResponse.error?.status ?? 401,
          errorMessage: userResponse.error?.message,
        };
      }
    } else {
      response.examinee = userResponse.data.user;
    }

    if (response.examinee) {
      const { data, error } = await client.from('examinees').select().eq('examinee_id', response.examinee.id)
      if (error || !data.length) {
        response.userId = undefined;
      } else {
        response.userId = data[0]['user_id']
      }
    }

    return response;
  } catch (error) {
    return {
      statusCode: 500,
      errorMessage: (error as any).message
    }
  }
}

